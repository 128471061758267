import React, { useContext } from 'react'
import styled from 'styled-components'
import { borders, breakpoints, colors, layout } from '../../theme'
import Navbar from '../../components/Navbar'
import SalesPictureTitle from '../../components/SalesPictureTitle'
import { union } from 'lodash'
import { useRedirect } from '../../hooks/useRedirect'
import { navigate } from '../../components/Link'
import { Button, StyledButton, Pulse } from '@monbanquet/crumble'
import { EventContext } from '../../components/context/EventContext'
import { defaultGuestTypes } from '../../components/context/EventContextDefaults'
import { useWithDelay } from '../../hooks/useWithDelay'

const GuestTypePage = ({ location: { state } }) => {
  const redirect = useRedirect(!state || !state.next, '/demande/format')

  const {
    state: { guestTypes, eventFraming },
    dispatch: dispatchEventCtx,
  } = useContext(EventContext)

  const submit = () => navigate('/demande/regime', { state: { next: true } })
  const [submitWithDelay, isDelayed] = useWithDelay(submit, 400)

  return (
    <StyledGuestTypePage>
      <Navbar />
      {redirect ? null : (
        <div className="page-content">
          <SalesPictureTitle
            title={
              <>
                {`Pour m’aider à choisir les bons produits,`}
                <br />
                {`pourriez-vous cocher ce qui qualifie le mieux les convives\u00A0?`}
              </>
            }
            subTitle="Vous pouvez en choisir plusieurs"
          />
          <form
            onSubmit={e => {
              e.preventDefault()
              submitWithDelay()
            }}
          >
            <fieldset className="guest-types">
              {defaultGuestTypes.map(q => (
                <label key={q.value}>
                  <input
                    type="checkbox"
                    value={q.value}
                    name="guestType"
                    checked={guestTypes.indexOf(q.value) !== -1}
                    autoFocus
                    onChange={e => {
                      dispatchEventCtx({
                        type: 'UPDATE_GUEST_TYPES',
                        payload: {
                          guestTypes: groupChecked(e, guestTypes),
                        },
                      })
                    }}
                  />
                  {q.label}
                </label>
              ))}
              <hr />
              <label>
                <input
                  type="checkbox"
                  name="eventFraming"
                  value="b2c"
                  checked={eventFraming === 'b2c'}
                  onChange={e =>
                    dispatchEventCtx({
                      type: 'UPDATE_EVENT_FRAMING',
                      payload: {
                        eventFraming: e.target.checked ? e.target.value : 'b2b',
                      },
                    })
                  }
                />
                {`Il s'agit d'un événement entre amis ou en famille`}
              </label>
            </fieldset>

            <div className="btn-bar">
              <Button id="previous" onClick={() => navigate(-1)}>
                Précédent
              </Button>
              <Button id="next" type="submit">
                {isDelayed ? <Pulse /> : <span>Suivant &rarr;</span>}
              </Button>
            </div>
          </form>
        </div>
      )}
    </StyledGuestTypePage>
  )
}

function groupChecked(e, arr) {
  if (!e.target.checked) {
    return arr.filter(q2 => q2 !== e.target.value)
  } else {
    return union(arr, [e.target.value])
  }
}

const StyledGuestTypePage = styled.div`
  .page-content {
    margin: ${layout.navbarHeight.normal}px auto;
    max-width: 550px;
    width: ${layout.width};
    text-align: center;
  }

  @media (max-width: ${breakpoints.mobile}px) {
    .page-content {
      margin-top: ${layout.navbarHeight.mobile}px;
    }
  }

  p.subTitle {
    color: ${colors.text.light2};
    text-align: center;
    font-size: 1.2rem;
    margin-top: 20px;
  }

  form {
    text-align: left;
    margin: 0 auto;
    margin-top: 50px;
  }

  fieldset {
    border: none;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;

    label {
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      input {
        margin-right: 10px;
      }
    }
  }

  hr {
    margin: 20px 0;
    background: #eee;
  }

  .btn-bar {
    display: flex;
    margin: 30px 0;

    & > *:first-child {
      margin-right: auto;
      margin-left: 0;
      background: transparent;
      border: 1px solid ${borders.color.light};
      color: ${colors.text.light};

      &:hover {
        box-shadow: 0 2px 4px 0 rgba(126, 126, 126, 0.11);
      }
    }

    ${StyledButton} {
      height: 38px;
      border-radius: 3px;
    }
  }
`

export default GuestTypePage
export { StyledGuestTypePage }
